import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { LayoutContext } from '../components/organisms/layoutProvider';
import SEO from '../components/organisms/seo';
import { media } from '../utils/media-queries';
import RichText from '../components/atoms/richtext';
import Arrow from '../assets/svgs/arrow.svg';

const Post = ({ data, pageContext, location }) => {
  const { language, theme } = useContext(LayoutContext);
  const raw = language === 'en' ? '_rawEn' : '_rawDe';

  const { title, slug, publishedAt, previewImage, content } = data.posts;
  const { next, prev } = pageContext;

  useEffect(() => {
    navigate(
      language === 'en' ? `/blog/${slug.current}` : `/de/blog/${slug.current}`,
      { replace: true }
    );
  }, [language, slug]);

  return (
    <>
      <SEO siteTitle={title[language || title.en]} />
      <StyledPost className="spacing-inner">
        <div className="desc">
          <div className="left">
            <div>
              <p className="hover">
                <Link to={language === 'en' ? `/blog` : `/de/blog`}>Blog</Link>
              </p>
              <br />
              <br />
              <p className="published headline">
                {publishedAt && (publishedAt[language] || publishedAt.en)}
              </p>
              <h2 className="title headline">{title[language || title.en]}</h2>
            </div>
            <div className="share">
              <p className="title">Share</p>
              <div style={{ fontFamily: 'var(--font-3)' }} className="links">
                <FacebookShareButton url={location.href} className="hover">
                  FB
                </FacebookShareButton>
                <TwitterShareButton
                  url={location.href}
                  via="Claudia Six"
                  className="hover"
                >
                  TW
                </TwitterShareButton>
              </div>
              <div>
                <Link
                  to={language === 'en' ? `/blog/${prev}` : `/de/blog/${prev}`}
                >
                  <p className="hover">
                    <span
                      style={{
                        transform: 'scaleX(-1)',
                        paddingLeft: 'var(--spacing-XXXS)',
                      }}
                    >
                      <Arrow className={theme === 'light' && 'dark'} />
                    </span>
                    Previous
                  </p>
                </Link>
                <Link
                  to={language === 'en' ? `/blog/${next}` : `/de/blog/${next}`}
                >
                  <p className="hover">
                    <span style={{ paddingRight: 'var(--spacing-XXXS)' }}>
                      <Arrow className={theme === 'light' && 'dark'} />
                    </span>
                    Next
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="right">
            <figure>
              <Img
                fluid={previewImage.image.asset.fluid}
                alt={previewImage.alt}
              />
            </figure>
          </div>
        </div>
        {content.map((item, i) => (
          <div className="content" key={i}>
            <div className="headline left">
              <RichText blocks={item.headline[raw] || item.headline._rawEn} />
            </div>
            <div className="right">
              {item.content.map(
                (contentItem, contentI) =>
                  (contentItem.__typename === 'SanityLocalePortableText' && (
                    <div className="copy" key={contentI}>
                      <RichText
                        blocks={contentItem[raw] || contentItem._rawEn}
                      />
                    </div>
                  )) ||
                  (contentItem.__typename === 'SanityMainImage' && (
                    <figure key={contentI}>
                      <Img
                        fluid={contentItem.image.asset?.fluid}
                        alt={contentItem.alt}
                      />
                    </figure>
                  )) ||
                  (contentItem.__typename === 'SanityVideo' && contentItem.url && (
                    <div className="video" key={contentI}>
                      <iframe
                        src={
                          contentItem.url.includes('youtu')
                            ? `https://www.youtube-nocookie.com/embed/${
                                contentItem.url.split('?v=')[1]
                              }`
                            : `https://player.vimeo.com/video/${
                                contentItem.url.split('.com/')[1]
                              }?dnt=1`
                        }
                        title="Video"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      />
                    </div>
                  ))
              )}
            </div>
          </div>
        ))}
      </StyledPost>
    </>
  );
};

const StyledPost = styled.section`
  min-height: 100vh;
  margin-top: var(--v-spacing-S);
  font-family: var(--font-1);

  .desc {
    padding-bottom: var(--v-spacing-M);

    .published {
      font-family: var(--font-3);
    }

    .title {
      margin-top: calc(var(--spacing-XXXS) * -1);
      padding-bottom: var(--spacing-S);
    }

    .share {
      display: flex;
      justify-content: space-between;

      span {
        display: none;
      }

      button {
        display: block;
      }

      .title {
        width: 33%;
      }

      * {
        padding-bottom: var(--spacing-XXS) !important;
      }
    }

    .right {
      display: none;
    }
  }

  .content {
    padding-bottom: var(--v-spacing-M);

    .headline {
      font-family: var(--font-3);
      padding-bottom: var(--v-spacing-M);
    }

    .copy {
      p {
        padding-bottom: var(--v-spacing-S);
      }
    }

    figure {
      padding-bottom: var(--v-spacing-S);
    }

    .video {
      margin-bottom: var(--v-spacing-S);
    }
  }

  @media ${media.L} {
    .desc {
      display: flex;
      padding-bottom: var(--spacing-XXL);

      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: var(--spacing-XL);

        .hover {
          a {
            text-decoration: none;
          }
        }
      }

      .right {
        display: block;
      }

      .left {
        width: 50%;
      }

      .right {
        width: 35%;

        .gatsby-image-wrapper {
          height: 52vw;
        }
      }

      .share {
        .title {
          width: 0;
        }

        * {
          padding-bottom: 0 !important;
        }

        span {
          display: inline-block;

          * {
            width: calc(var(--copy) * 0.8);
            height: calc(var(--copy) * 0.8);
          }

          .dark > path {
            fill: var(--black);
          }
        }
      }
    }

    .content {
      display: flex;

      .left,
      .right {
        width: 50%;
      }
    }

    .next {
      display: flex;
      transform: translateX(calc(var(--v-spacing-L) * -1));

      .arrow {
        transform: translateY(13px);
        padding-right: var(--spacing-XXS);
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    posts: sanityBlogPost(slug: { current: { eq: $slug } }) {
      title {
        de
        en
      }
      slug {
        current
      }
      publishedAt {
        de
        en
      }
      previewImage {
        image {
          asset {
            fluid(maxWidth: 3600) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
        alt
      }
      content {
        headline {
          _rawDe
          _rawEn
        }
        content {
          ... on SanityLocalePortableText {
            _rawDe
            _rawEn
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
          ... on SanityVideo {
            url
          }
        }
      }
    }
  }
`;

Post.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default Post;
